import React from 'react'
import styled from "styled-components"

const FooterWrapper = styled.footer`
    grid-area: auto / 1 / auto / -1;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: .5rem;
    grid-row-gap: 1rem;

    nav{
      grid-area: 1 / 1 / auto / -1;
      ul{
        list-style:none;
        margin: 0;
        padding: 0;

        li{
          margin: 0 0 0.5rem 0;
          line-height: 1;

          a{
            font-family: "Nimbus Sans L", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
            font-weight: 400;
            font-size: 1rem;
            text-transform: uppercase;
            font-style: normal;
            text-decoration: none;
            position: relative;
            
            span::after {
              content: '';
              height: 2px;
              width: 100%;
              position: absolute;
              bottom: 0;
              left: 0;
              background: #1a1a1a;
              transform: scale3d(0, 1, 1);
              transform-origin: center right;
              transition: transform .75s cubic-bezier(.86,0,.07,.995); 
            }
          }

          a:hover span::after {
            transform-origin: center left;
            transform: scale3d(1, 1, 1);
          }
        }
      }
    }
    
    section {
      grid-area: auto / 2 / auto / -1;
      p{
        font-size: .875rem;
      }
    }


  @media (min-width: 45em){
      grid-column-gap: 1rem;

      section{
          grid-area: 1 / 2 / auto / 8;
      }
      nav{
        grid-area: auto / 8 / auto / -1;
      }
  }
`;

const Footer = () => {
  return (
    <FooterWrapper
      data-sal="fade"
      data-sal-delay="0"
      data-sal-duration="500"
      data-sal-easing="ease">
      <nav>
        <ul>
          <li>
            <a href="https://instagram.com/keiththompson.studio" target="_blank" rel="noopener noreferrer"><span>&#8599;Instagram</span></a>
          </li>
        </ul>
      </nav>
      <section>
        <p>
        *And other interesting projects like portfolios, landing pages, pop-up shops, etc. †Or if you’d just like to say hi.
        </p>
        <p>
        © {new Date().getFullYear()} Keith Thompson Studio LLC. All Rights Reserved. Type is set in Junicode by Peter S. Baker and Nimbus Sans L by URW++.
        </p>
      </section>
      
      
    </FooterWrapper>
  )
}

export default Footer
